import React from "react"

import { useStaticQuery, graphql } from "gatsby"

export default function AboutHeaderBanner() {
  var header = useStaticQuery(graphql`
    {
      header: allFile(
        filter: {
          relativeDirectory: { eq: "aboutPage" }
          name: { eq: "header" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  header = header.header.edges[0].node

  return (
    <div
      id="about-us"
      className="md:w-3/4 md:justify-between md:items-start md:flex-row-reverse md:mt-16 w-full flex flex-col-reverse justify-center items-center px-2"
    >
      <div className="flex md:w-7/12 md:self-stretch md:items-start md:justify-between w-full justify-center items-center flex-col my-3">
        <div className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl 2xl:[line-height:70px] xl:[line-height:50px]  font-helvetica font-bold text-text-primary uppercase md:text-left text-center mb-2">
          about us
        </div>
        <div className="w-full 2xl:text-xl md:text-sm text-sm md:mb-24 md:text-left font-thin font-helvetica text-text-primary text-center lg:mt-8">
          <div className="w-full flex justify-center items-center md:text-left text-center lg:mb-10 mb-10">
            VSN Shared Services LLP is a full-service accounting and auditing
            business process offshoring company inter-alia practicing in the
            area of Accounting, Audit & Assurance, Book Keeping, Tax Advisory,
            Payroll Processing, Fund Management, VCFO or part-time CFO services,
            Budgeting and Compliance Support.
          </div>
          <div className="w-full flex justify-center items-center md:text-left text-center">
            We understand the complexities of offshoring business processes.
            Accordingly, our team provides solutions to clients within the best
            practices and per International Standards. We established our
            practice with a commitment to providing the best and most
            comprehensive services to all our clients. We are acclaimed for our
            highly efficient, proficient, and experienced team of professionals,
            having a personalized approach while deliberating over the issues.
          </div>
        </div>
      </div>
      <div className="w-6/12 pt-2 md:self-stretch md:mr-20">
        <img className="h-full" src={header.publicURL} alt="about" />
      </div>
    </div>
  )
}
