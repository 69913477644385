import React, { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { teamData } from "./team"

import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Popup = ({ data, setModalOpen, close }) => {
  return (
    <div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.2)] w-screen h-screen flex justify-center items-center">
      <div className="m-5 lg:w-1/2 bg-white border-2 border-text-primary rounded-xl px-5 pb-10 pt-5">
        <div className="w-full flex justify-end">
          <div>
            <label
              className="cursor-pointer"
              onClick={() => setModalOpen(false)}
            >
              <img src={close} alt="close" width={40} />
            </label>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center modal-content modal-scrollable">
          <div className="w-52 h-52 rounded-full overflow-hidden flex justify-center items-center border-2 border-text-primary">
            <img
              src={`/images/aboutPage/team/${data.avatar}`}
              className="rounded-full w-full h-full"
            />
          </div>
          <h1 className="text-center text-text-primary uppercase font-bold text-lg md:text-xl lg:my-5 my-2">
            {data.name}
          </h1>
          <p className="text-center text-text-primary text-[0.75rem] md:text-sm xl:text-lg">
            {data.intro}
          </p>
          <p className="text-center text-text-primary text-[0.75rem] md:text-sm xl:text-lg">
            {data.extra}
          </p>
        </div>
      </div>
    </div>
  )
}

const Member = ({ data, image, setModalOpen, pad = false }) => {
  return (
    <div onClick={setModalOpen}>
      <div className="md:w-96 md:h-96 w-80 h-auto p-5 aspect-square flex flex-col justify-center items-center">
        <div
          className={`gradient-member md:p-5 p-5 w-full aspect-square flex flex-col justify-evenly items-center rounded-xl shadow-black drop-shadow-2xl transition-all cursor-pointer  hover:bg-card-member-hover`}
        >
          <div className="w-52 h-52 rounded-full overflow-hidden flex justify-center items-center border-2 border-text-primary">
            <img
              src={`/images/aboutPage/team/${image}`}
              className="rounded-full w-full h-full"
            />
          </div>

          <h1 className="text-text-primary text-center font-bold uppercase text-2xl md:my-2 my-2">
            {data.name}
          </h1>
          <p className="text-text-primary text-center text-lg">
            {data.designation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default function TeamSection() {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {
          relativeDirectory: { eq: "aboutPage/team" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            name
            childrenImageSharp {
              gatsbyImageData(aspectRatio: 1)
            }
          }
        }
      }
      close: allFile(
        filter: {
          relativeDirectory: { eq: "contactPage/popup" }
          name: { eq: "close" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  var { images, close } = data
  const imagesEdges = images.edges
  close = close.edges[0].node.publicURL

  images = {}

  imagesEdges.map(
    image =>
      (images[image.node.name] =
        image.node.childrenImageSharp[0].gatsbyImageData)
  )

  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleOpenModal = data => {
    setModalData(data)
    setModalOpen(true)
  }

  return (
    <>
      <div className="w-full flex justify-center items-center" id="team">
        <div className="lg:w-10/12 w-full flex-col justify-center items-center p-5">
          <div className="w-full flex flex-col justify-center items-center my-10">
            <div className="2xl:text-4xl xl:text-3xl md:text-2xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center my-10">
              <h1>meet our team</h1>
            </div>
            <div className="w-3/4 2xl:text-xl md:text-sm text-sm font-thin font-helvetica text-text-primary text-center">
              <p>
                Our methodical approach provides not only unparalleled services,
                we also provide creative answers and effective solutions to
                challenges faced by our clients.{" "}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center lg:mt-10">
              <h1>our founders</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              {teamData.founders.map((founder, idx) => (
                <Member
                  key={idx}
                  data={founder}
                  image={founder.avatar}
                  idx="f-1"
                  setModalOpen={() => handleOpenModal(founder)}
                />
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center mt-20">
              <h1>key strategic advisors</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              {teamData.strategicAdvisors.map((advisor, idx) => {
                return (
                  <Member
                    data={advisor}
                    image={advisor.avatar}
                    key={idx}
                    setModalOpen={() => handleOpenModal(advisor)}
                  />
                )
              })}
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center mt-10">
              <h1>our associates</h1>
            </div>
            <div className="w-full grid md:grid-cols-2 xl:grid-cols-3 my-5 justify-items-center">
              {teamData.associates.map((associate, idx) => {
                return (
                  <Member
                    data={associate}
                    image={associate.avatar}
                    key={idx}
                    setModalOpen={() => handleOpenModal(associate)}
                  />
                )
              })}
            </div>
          </div>
          {modalOpen && (
            <Popup
              data={modalData}
              open={modalOpen}
              setModalOpen={setModalOpen}
              close={close}
            />
          )}
        </div>
      </div>
    </>
  )
}
