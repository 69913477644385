const teamData = {
  founders: [
    {
      name: "vaibhav jain",
      avatar: "CA-Vaibhav.jpeg",
      designation: "Founder",
      intro:
        "A qualified Chartered Accountant and law graduate (LL.B) having an experience of more than a decade in the field of Accounting, Bookkeeping, Auditing, Internal Audit, Risk Advisory, Direct and Indirect Taxes.",
      extra:
        "A certified Quick book, Turbo Tax, and Xero accounting professional. Associated with ICAI (Institute of Chartered Accountants of India) as visiting faculty and has also authored a book on GST Titled “GST & Customs Law” published by Bloomsbury. Author of research paper on the Seamless flow of “Input Tax Credit” published by ICAI. Special Invitee to an echelon in the “Indirect Tax Committee” of ICAI. Imparted training to over 5,000 aspiring CA students during their GMCS course on time management and personality development.",
    },
    {
      name: "shikha jain",
      avatar: "shikha_jain.png",
      designation: "Founder",
      intro:
        "A qualified Chartered Accountant with a professional experience of over 8 years. Possess an experience in the field of system automation and compliance support in relation to Company Law and Foreign Exchange Regulations. Core specialization is in cost optimization, revenue growth, and margin/profit maximization across business processes.",
      extra:
        "A certified Quick book, Turbo Tax, and Xero accounting professional. Conducted the statutory Branch Audit for the State Bank of India for more than 11 branches. Awarded Spot Award for her excellent work and for showing great enthusiasm for every piece allocated at HCL. Proficiency in Excel and special techniques to save cost by simplifying the process which is being acclaimed by many of the clients. Her last assignment was with HSBC, wherein she was part of the Stress Testing Team as Assistant Manager for the USA region and worked on Comprehensive Capital Analysis and Review (CCAR), Dodd-Frank Stress Test (DFAST).",
    },
  ],
  strategicAdvisors: [
    {
      name: "k.k jain",
      avatar: "k.k_jain.png",
      designation: "Managing partner of Krishan Kumar Jain & Co.",
      intro:
        "Possesses a rich and vast experience in the field of litigation and Accounting services Auditing, and Assurance, Fund Management, VCFO, or part-time CFO services. In practice since 1987. Core specialization is in Accounting, Auditing, and Assurance, Direct and Indirect Taxation. In the past 30 years of his professional career, he has been appearing before various judicial and quasi-judicial authorities/forums in matters relating to Direct Taxes and Indirect Taxes which has helped him in getting useful insights into practical and legal nuances of judicial and quasi-judicial authorities/forums.",
      extra:
        "Appointed as a professional director in the Innovative Urban Cooperative Bank, New Delhi. Past Chairman of the Sonepat CP Study Circle of ICAI. Founding members of an NGO named CA Parivaar which helps the underprivileged CA Students and Newly qualified Chartered Account professionals in overall growth and development",
    },
    {
      name: "rakesh jain",
      avatar: "rakesh_jain.png",
      designation:
        "Managing partner of Falcon International Consulting & Auditing",
      intro:
        "Managing partner at Falcon International Consulting & Auditing having its Head Office in Dubai and branches in Abu Dhabi & Sharjah is a well-reputed Financial & Business consultancy and Audit Firm in UAE duly approved by the Ministry of Economy, Government of United Arab Emirates, and by all major Banks.",
      extra:
        "FICA serves varied sectors in the areas of Audit Assurance, Business Risk Analysis, Local & Offshore Company Formation & Liquidation, Feasibility Studies & Project Financing, International Tax Planning, Assets Protection, Accounting Services and Business, Financial & Wealth Advisory services. FICA shall be your best choice when you look for Auditors, Accountants and Business, Financial & Wealth Advisory Services in Dubai, Abu Dhabi & all over United Arab Emirates. We aim financial success of our clients and committed to earn their trust and in return equip them with confidence in presenting their financial management to compliance authorities and other stake holders.",
    },
  ],
  associates: [
    {
      name: "rahul chopra",
      avatar: "rahul_chopra.png",
      designation: "Associate",
      intro:
        "He has a stellar record in the field of Audit and Assurance, Bank Audits, and Compliance Support.",
      extra:
        "he has been associated extensively in conducting Audits of various scheduled banks like BOI, State Bank of India, and State bank of Patiala. Besides Banks Audits, He  is mentoring the Audits in various sectors including Information Technology, Iron & Steel, Retail, Financial Services, Education, Real Estate, and others.",
    },
    {
      name: "vishal jain",
      avatar: "vishal_jain.png",
      designation: "Associate",
      intro:
        "He has a professional experience of more than 8 years and a stellar record in the field of Internal Audits and Tax Audits.	His technology-driven and problem-solving approach toward the client’s issues especially during internal audits has led to immense cost-saving and resource-saving for clients. He is well versed with all the latest technological accounting tools.",
      extra:
        " His forte has been remote bookkeeping and financial modeling. He has a first hands-on experience working on QuickBooks, Xero, Wave, Myob, Clearbooks, Zoho Books, Tally 9 ERP, Busy, and others.",
    },
    {
      name: "Anmol Aggarwal",
      avatar: "Anmol.jpg",
      designation: "Associate",
      intro:
        "Anmol is a qualified Chartered Accountant at VSN Shared Services, specializing in Income Tax and bookkeeping. With a deep understanding of tax laws and financial regulations, he ensures accurate tax planning, compliance, and advisory services. His expertise helps clients navigate complex income tax regulations efficiently while optimizing their financial strategies.",
      extra:
        "Additionally, Anmol’s proficiency in bookkeeping ensures seamless financial management and reporting. His analytical approach and attention to detail make him a trusted advisor for businesses seeking reliable tax and accounting solutions.",
    },
    {
      name: "Ranjeet Verma",
      avatar: "CA-Ranjeet.jpeg",
      designation: "Associate",
      intro:
        "Ranjeet Verma is a Chartered Accountant specializing in Indirect Taxes at VSN Shared Services. His expertise ensures compliance with GST and other indirect tax regulations, helping businesses navigate complex tax structures efficiently. ",
      extra:
        "Since your target audience includes CPAs and ACCAs in the UK, positioning your services to highlight expertise in global tax compliance, VAT regulations, and cross-border transactions can enhance your outreach.",
    },
    {
      name: "Sachin",
      avatar: "Sachin.jpeg",
      designation: "Associate",
      intro:
        "Sachin is a semi-qualified professional at VSN Shared Services, specializing in GST for Australia. His expertise helps businesses stay compliant with Australian tax regulations, ensuring accurate reporting and efficient tax management.",
      extra: "",
    },
    {
      name: "Aayushi jain",
      avatar: "Aayushi-Jain.jpeg",
      designation: "Associate",
      intro:
        "Aayushi is a CA Intern at VSN Shared Services, specializing in bookkeeping using Xero and QuickBooks. With a keen eye for detail and a strong understanding of financial records, she ensures accurate and efficient accounting processes for clients. Her expertise in cloud-based accounting solutions makes her a valuable asset to the firm.",
      extra: "",
    },
    {
      name: "Sarthak",
      avatar: "Saarthak.jpeg",
      designation: "Associate",
      intro:
        "Sarthak is a CA Intern at VSN Shared Services, specializing in bookkeeping. His expertise ensures accurate financial records and smooth accounting operations for clients.",
      extra: "",
    },
    {
      name: "Aashutosh Jha",
      avatar: "Ashutosh.jpeg",
      designation: "Associate",
      intro:
        "Ashutosh is a semi-qualified professional at VSN Shared Services, specializing in income tax matters. He assists in tax compliance, planning, and advisory services, ensuring clients meet their tax obligations efficiently.",
      extra: "",
    },
    {
      name: "Ashwini Gupta",
      avatar: "Ashwini-Gupta.jpeg",
      designation: "Associate",
      intro:
        "Ashwini Gupta is the most senior accountant at VSN Shared Services, bringing over 17 years of experience in bookkeeping and financial management. Since joining the organization in 2007, he has played a crucial role in maintaining accurate financial records and ensuring compliance with accounting standards. With a deep understanding of bookkeeping principles and a meticulous approach to financial data, Ashwini is a trusted expert in managing complex accounts.",
      extra:
        "His dedication and extensive experience make him an invaluable pillar of the organization’s accounting team.",
    },
    {
      name: "Gopal",
      avatar: "Gopal.jpeg",
      designation: "Associate",
      intro:
        "Gopal is a highly experienced accountant at VSN Shared Services, having been an integral part of the organization since 2000. With over two decades of expertise in financial management and bookkeeping, he brings a wealth of knowledge to the team. His deep understanding of accounting principles, attention to detail, and commitment to accuracy have made him a trusted professional within the firm.",
      extra:
        "Gopal’s long-standing association with the organization highlights his dedication and invaluable contribution to its success.",
    },
    {
      name: "Ajeet",
      avatar: "Ajeet.jpeg",
      designation: "Associate",
      intro:
        "-Ajeet is a dedicated semi-qualified professional at VSN Shared Services, specializing in bookkeeping on online accounting software. With a keen eye for detail and a strong understanding of financial processes, he ensures accurate and efficient management of financial records for clients. His expertise spans multiple cloud- based accounting platforms, enabling seamless bookkeeping, reconciliations, and financial reporting. ",
      extra:
        "Ajeet’s commitment to precision and efficiency makes him an invaluable asset to businesses looking for streamlined financial management.",
    },
  ],
}

module.exports = { teamData: teamData }
